export const base = '#003d7c'
export const black = '#384048'
export const gray0 = '#f9f9f9'
export const gray1 = '#ecedee'
export const gray2 = '#dfe0e2'
export const gray3 = '#d1d3d4'
export const gray4 = '#c1c4c6'
export const gray5 = '#b0b3b6'
export const gray6 = '#9ca0a4'
export const gray7 = '#84898e'
export const gray8 = '#666c72'
export const gray9 = '#384048'
export const blue0 = '#e7edf2'
export const blue1 = '#ccd9e5'
export const blue2 = '#aec1d5'
export const blue3 = '#89a6c2'
export const blue4 = '#5881a9'
export const blue5 = '#003e7c'
export const blue6 = '#00376e'
export const blue7 = '#002f5f'
export const blue8 = '#00264d'
export const blue9 = '#001933'
export const indigo0 = '#eaeaf4'
export const indigo1 = '#d4d4e9'
export const indigo2 = '#babadb'
export const indigo3 = '#9a9acb'
export const indigo4 = '#6e6eb4'
export const indigo5 = '#00007c'
export const indigo6 = '#00006f'
export const indigo7 = '#000061'
export const indigo8 = '#000050'
export const indigo9 = '#000038'
export const violet0 = '#efe9f4'
export const violet1 = '#ddd2e8'
export const violet2 = '#c8b6d9'
export const violet3 = '#af95c8'
export const violet4 = '#8c68b1'
export const violet5 = '#3e007c'
export const violet6 = '#37006f'
export const violet7 = '#300060'
export const violet8 = '#27004e'
export const violet9 = '#1a0035'
export const fuschia0 = '#f3e9f3'
export const fuschia1 = '#e7d1e7'
export const fuschia2 = '#d9b5d9'
export const fuschia3 = '#c792c7'
export const fuschia4 = '#af64af'
export const fuschia5 = '#7c007c'
export const fuschia6 = '#6f006f'
export const fuschia7 = '#610061'
export const fuschia8 = '#500050'
export const fuschia9 = '#380038'
export const pink0 = '#f4e9ee'
export const pink1 = '#e7d1dc'
export const pink2 = '#d9b5c7'
export const pink3 = '#c793ad'
export const pink4 = '#b0658a'
export const pink5 = '#7c003e'
export const pink6 = '#6f0037'
export const pink7 = '#610030'
export const pink8 = '#4f0027'
export const pink9 = '#38001c'
export const red0 = '#f4e9e9'
export const red1 = '#e8d2d2'
export const red2 = '#d9b6b6'
export const red3 = '#c89595'
export const red4 = '#b16767'
export const red5 = '#7c0000'
export const red6 = '#6f0000'
export const red7 = '#610000'
export const red8 = '#500000'
export const red9 = '#380000'
export const orange0 = '#f2ece5'
export const orange1 = '#e3d6c9'
export const orange2 = '#d3bea9'
export const orange3 = '#bfa183'
export const orange4 = '#a67b51'
export const orange5 = '#7c3e00'
export const orange6 = '#6f3700'
export const orange7 = '#603000'
export const orange8 = '#4e2700'
export const orange9 = '#351a00'
export const yellow0 = '#f0f0e2'
export const yellow1 = '#dfdfc2'
export const yellow2 = '#cdcd9e'
export const yellow3 = '#b8b874'
export const yellow4 = '#9e9e42'
export const yellow5 = '#7c7c00'
export const yellow6 = '#6f6f00'
export const yellow7 = '#616100'
export const yellow8 = '#505000'
export const yellow9 = '#383800'
export const lime0 = '#eaf0e3'
export const lime1 = '#d3e1c4'
export const lime2 = '#b8cfa2'
export const lime3 = '#9aba79'
export const lime4 = '#73a047'
export const lime5 = '#3e7c00'
export const lime6 = '#376f00'
export const lime7 = '#306100'
export const lime8 = '#274f00'
export const lime9 = '#1c3800'
export const green0 = '#e5f1e5'
export const green1 = '#c8e2c8'
export const green2 = '#a7d2a7'
export const green3 = '#80bd80'
export const green4 = '#4da34d'
export const green5 = '#007c00'
export const green6 = '#006f00'
export const green7 = '#006100'
export const green8 = '#005000'
export const green9 = '#003800'
export const teal0 = '#e4f1eb'
export const teal1 = '#c7e2d4'
export const teal2 = '#a5d1bb'
export const teal3 = '#7ebc9d'
export const teal4 = '#4ba277'
export const teal5 = '#007c3e'
export const teal6 = '#006f37'
export const teal7 = '#006130'
export const teal8 = '#005028'
export const teal9 = '#00381c'
export const cyan0 = '#e4f1f1'
export const cyan1 = '#c6e1e1'
export const cyan2 = '#a4d0d0'
export const cyan3 = '#7cbbbb'
export const cyan4 = '#49a1a1'
export const cyan5 = '#007c7c'
export const cyan6 = '#006f6f'
export const cyan7 = '#006161'
export const cyan8 = '#005050'
export const cyan9 = '#003838'
