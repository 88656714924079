import React from 'react'

import cn from 'classnames'

import styles from './style.module.scss'

import Button from '../button'

import pluralize from 'pluralize'

const totalNumberOfButtons = 5

export default ({ numPages, currentPage, baseLink, onChange = () => {} }) => {
    const updateCurrentPage = value => {
        onChange(value)
    }

    if (numPages < 1) return null

    let sliceStart = Math.max(
        currentPage - Math.ceil(totalNumberOfButtons / 2),
        0
    )

    let sliceEnd = sliceStart + totalNumberOfButtons

    return (
        <ul
            className={cn(styles.pagination, {
                [styles.singlePage]: numPages === 1
            })}
        >
            <li>
                <Button
                    link={baseLink}
                    className={cn(
                        { [styles.disabled]: currentPage === 1 },
                        styles.button
                    )}
                    onClick={() => {
                        !baseLink && updateCurrentPage(1)
                    }}
                >
                    First
                </Button>
            </li>
            {Array.from({ length: numPages })
                .map((_, i) => i)
                .slice(sliceStart, sliceEnd)
                .map(i => {
                    return (
                        <li key={i}>
                            <Button
                                {...{
                                    link: baseLink
                                        ? i === 0
                                            ? baseLink
                                            : `${baseLink}/${i + 1}`
                                        : undefined
                                }}
                                className={cn(
                                    styles.number,
                                    {
                                        [styles.active]: i + 1 === currentPage
                                    },
                                    styles.button
                                )}
                                onClick={() => updateCurrentPage(i + 1)}
                            >
                                {i + 1}
                            </Button>
                        </li>
                    )
                })}
            <li>
                <Button
                    {...{
                        link: baseLink ? `${baseLink}/${numPages}` : undefined
                    }}
                    onClick={() => updateCurrentPage(numPages)}
                    className={cn(
                        {
                            [styles.disabled]: currentPage === numPages
                        },
                        styles.button
                    )}
                >
                    Last
                </Button>
            </li>
        </ul>
    )
}

export const paginate = ({ array, pageSize, currentPage }) => {
    return {
        array: array.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
        ),
        numPages: Math.ceil(array.length / pageSize)
    }
}

export const paginateText = ({
    pageSize,
    currentPage,
    numPages,
    count,
    entity
}) => {
    if (+count === 0) {
        return 'No results matched, please refine your search criteria'
    }
    if (numPages === 1) {
        return `Showing ${count} ${pluralize(entity, count)}`
    }

    return `Showing from ${(currentPage - 1) * pageSize + 1} to ${Math.min(
        currentPage * pageSize,
        count
    )} out of ${count} ${pluralize(entity)}`
}
