import React, { useState, useRef, useEffect } from 'react'

import styles from './style.module.scss'

import Select from 'react-select'

import * as primaryPalette from '../../styles/primary-pallete'

import Pagination, { paginate } from '../pagination'

import { formatter } from '../format'

import { get, isEmpty } from 'lodash-es'

import cn from 'classnames'

function removeDuplicates(options) {
    return options.reduce((unique, item) => {
        const found = !!unique.find(uItem => uItem.value === item.value)

        return found ? unique : [...unique, item]
    }, [])
}

var _locationOptions = null
const locationOptions = data => {
    if (_locationOptions) return _locationOptions

    _locationOptions = data.jobs.nodes.map(
        job =>
            !isEmpty(job.acf.address) && {
                value: job.acf.address.toLowerCase().trim(),
                label: formatter.titleCase(job.acf.address)
            }
    )
    // filter empty and duplicates
    _locationOptions = _locationOptions.filter(c => c)

    _locationOptions = removeDuplicates(_locationOptions)

    _locationOptions = [
        {
            value: null,
            label: 'All locaitons'
        },
        ..._locationOptions
    ]

    return _locationOptions
}

var _careerOptions = null
const careerOptions = data => {
    if (_careerOptions) return _careerOptions

    _careerOptions = data.jobs.nodes.map(job => ({
        value: job.title.toLowerCase().trim(),
        label: formatter.titleCase(job.title)
    }))
    // filter empty and duplicates
    _careerOptions = _careerOptions.filter(c => c)

    _careerOptions = removeDuplicates(_careerOptions)

    _careerOptions = [
        {
            value: null,
            label: 'All designations'
        },
        ..._careerOptions
    ]
    return _careerOptions
}

function filterJobs(jobs, filter) {
    return jobs.filter(job => {
        let matched = true
        if (!isEmpty(filter.address) && !isEmpty(filter.address.value)) {
            matched =
                matched &&
                job.acf.address.toLowerCase().trim() === filter.address.value
        }

        if (!isEmpty(get(filter, 'title.value'))) {
            matched =
                matched && job.title.toLowerCase().trim() === filter.title.value
        }

        return matched
    })
}

const selectTheme = theme => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        // primary25: secondaryPalette.base,
        primary: primaryPalette.base
    }
})

function transformFieldName(field) {
    const map = {
        title: 'title',
        content: null,
        link: null
    }

    return typeof map[field] !== 'undefined' ? map[field] : field
}

function renderJobFields(job) {
    if (!job) return []

    return Object.keys(job).map(field => {
        if (typeof job[field] === 'object') return renderJobFields(job[field])
        let fieldName = transformFieldName(field)
        if (fieldName === 'wordpress_id') return null
        return (
            fieldName && (
                <tr>
                    <td className={styles.fieldKey}>
                        {formatter.snakeToTitleCase(fieldName)}
                    </td>
                    <td
                        className={styles.fieldValue}
                        dangerouslySetInnerHTML={{
                            __html: formatter.emptyValue(job[field])
                        }}
                    />
                </tr>
            )
        )
    })
}

function isElement(element) {
    return element instanceof Element || element instanceof HTMLDocument
}

function isContentLarge(ref) {
    if (typeof window === 'undefined') return false

    if (!isElement(ref.current)) {
        return true
    }

    const rect = ref.current.getBoundingClientRect()

    return rect.height >= 200
}

function JobContent({ job }) {
    const ref = useRef({})
    const [expanded, setExpaned] = useState(true)
    useEffect(() => {
        setTimeout(function() {
            setExpaned(false)
        }, 10)
    }, [])
    return (
        <>
            <div
                ref={ref}
                className={cn(styles.content, {
                    [styles.expanded]: expanded
                })}
                dangerouslySetInnerHTML={{
                    __html: job.content
                }}
            ></div>
            {isContentLarge(ref) && (
                <div
                    className={styles.readMore}
                    onClick={() => setExpaned(!expanded)}
                >
                    {expanded ? 'read less' : 'read more'}
                </div>
            )}
        </>
    )
}

export default ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1)

    const [filter, setFilter] = useState({})

    const updateFilter = data => {
        setFilter({ ...filter, ...data })
        setCurrentPage(1)
    }

    const pageSize = 10

    const filtered = filterJobs(data.jobs.nodes, filter)

    const { array: jobs, numPages } = paginate({
        array: filtered,
        pageSize,
        currentPage
    })

    return (
        <div className="wrapper">
            <div className="row">
                <div className="col-xs-12">
                    <h1 className={styles.pageTitle}>Real Estate Jobs</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6">
                    <div>What</div>
                    <Select
                        options={careerOptions(data)}
                        theme={selectTheme}
                        placeholder="Position"
                        onChange={title => updateFilter({ title })}
                    />
                </div>
                <div className="col-xs-6">
                    <div>Where</div>
                    <Select
                        options={locationOptions(data)}
                        theme={selectTheme}
                        placeholder="Dubai"
                        onChange={address => updateFilter({ address })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    {jobs.map(job => (
                        <div key={job.wordpress_id} className={styles.job}>
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3 className={styles.name}>{job.title}</h3>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <table className={styles.jobFieldsTable}>
                                        {renderJobFields(job)}
                                    </table>
                                </div>
                                <div className={cn('col-xs-12 col-sm-6')}>
                                    <JobContent job={job} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {!numPages && (
                <div className="row">
                    <div className="col-xs-12">
                        <p>No Jobs found! please refine your search criteria</p>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-xs-12">
                    <Pagination
                        numPages={numPages}
                        currentPage={currentPage}
                        onChange={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    )
}
