import numeral from 'numeral'
import isEmpty from 'lodash.isempty'

export const formatter = {
    price(value) {
        return 'AED ' + numeral(value).format('0,0')
    },
    size(value, { long }) {
        return long
            ? `${value}sqft / ${Math.round(value * 0.092903)}m2`
            : `${value}sqft`
    },
    id(value) {
        return `#${value}`
    },
    fees(value) {
        if (isEmpty(value)) return '---'
        if (value < 50) return `${value}%`
        return formatter.price(value)
    },
    titleCase(value) {
        var splitStr = value.toLowerCase().split(' ')
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
        // Directly return the joined string
        return splitStr.join(' ')
    },
    snakeToTitleCase(value) {
        return formatter.titleCase(value.replace(/_/g, ' '))
    },
    emptyValue(value) {
        if (isEmpty(value)) return '---'
        return value
    }
}

export default ({ value, type, ...params }) => formatter[type](value, params)
